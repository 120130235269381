import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen6 } from '../../../components/images/support/tasks/Screen6.generated';
import { Screen9 } from '../../../components/images/support/tasks/Screen9.generated';
import { Screen11 } from '../../../components/images/support/tasks/Screen11.generated';
import { Screen11a } from '../../../components/images/support/tasks/Screen11a.generated';
import { Screen10 } from '../../../components/images/support/tasks/Screen10.generated';
import { Screen26 } from '../../../components/images/support/tasks/Screen26.generated';
import { Screen28 } from '../../../components/images/support/tasks/Screen28.generated';
import { Screen30 } from '../../../components/images/support/tasks/Screen30.generated';
import { Screen32 } from '../../../components/images/support/tasks/Screen32.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "task-types-"
    }}>{`Task Types 📝`}</h1>
    <p>{`This is a guide to the different tasks types in a Workflow and some of their potential applications.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "e-sign-document-task"
    }}>{`E-sign document task`}</h2>
    <p>{`The `}<strong parentName="p">{`E-sign document`}</strong>{` task is used to collect an electronic signature from an employee.`}</p>
    <p>{`We can upload a document that an employee will have to read, accept and sign with a digital signature.`}</p>
    <p>{`We can ask the employee to sign documents such as:`}</p>
    <ul>
      <li parentName="ul">{`Policies`}</li>
      <li parentName="ul">{`Procedures`}</li>
      <li parentName="ul">{`Employment contracts`}</li>
      <li parentName="ul">{`Any document in a pdf format`}</li>
    </ul>

    <Screen6 mdxType="Screen6" />
    <p>{`If required we can configure the `}<strong parentName="p">{`E-sign document`}</strong>{` task so that the document is uploaded after the Workflow is assigned. For example upload an employment contract after you assign an employee onboarding workflow.`}</p>
    <p>{`To do this this we select the `}<strong parentName="p">{`Unique document`}</strong>{` option.`}</p>
    <p>{`This is best used for cases where we need to get a document accepted that is specific for an individual employee. For example an employment contract.`}</p>
    <p>{`The handy thing here is that when the document needs uploading the administrators will see it as an action item on their dashboard.`}</p>

    <Screen9 mdxType="Screen9" />
    <hr></hr>
    <h2 {...{
      "id": "form-task"
    }}>{`Form task`}</h2>
    <p>{`The `}<strong parentName="p">{`Form`}</strong>{` task is used in cases where we want to build a custom form to collect data from a member (employee, manager, owner, bookkeeper) of the organisation. This is particularly powerful in collecting information that is specific to the organisation needs.`}</p>
    <p>{`When we use the form task we can add multiple sections to the form as needed. The form sections are comprised of:`}</p>
    <p>{`Questions:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Short questions`}</strong>{` - giving members a short field to fill in.`}</li>
      <li parentName="ul"><strong parentName="li">{`Long questions`}</strong>{` - giving members a long field to fill in.`}</li>
      <li parentName="ul"><strong parentName="li">{`Multiple choice questions`}</strong>{` - asking the member to select from predefined options.`}</li>
    </ul>
    <p>{`Information:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Text block`}</strong>{` - a text editor where you can write information you would like to give to the member.`}</li>
      <li parentName="ul"><strong parentName="li">{`Video`}</strong>{` - upload a video into Canyou for a member to view from their device.`}</li>
      <li parentName="ul"><strong parentName="li">{`Youtube video`}</strong>{` - add a link to a Youtube video for the member to view from their device.`}</li>
    </ul>
    <p>{`Uploads: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Document upload`}</strong>{` - asking the member to upload a specific document or image.`}</li>
    </ul>

    <Screen11 mdxType="Screen11" />
    <p>{`A form task can be assigned to any member in the organisation. This allows you to create Workflows that involve multiple team members. `}</p>
    <p>{`Examples include:`}</p>
    <ul>
      <li parentName="ul">{`Adding a form task for a Supervisor to give a new employee a site induction.`}</li>
      <li parentName="ul">{`Requesting employee payroll specifics from a business owner when a new employee starts.`}</li>
      <li parentName="ul">{`Adding a to-do item for an administrator when a new employee starts.`}</li>
    </ul>

    <Screen11a mdxType="Screen11a" />
    <hr></hr>
    <h2 {...{
      "id": "user-profile-task"
    }}>{`User profile task`}</h2>
    <p>{`This task is to collect the users details to build a user profile in Canyou. This profile can be synchronised to Xero as an employee if needed.`}</p>

    <Screen10 mdxType="Screen10" />
    <hr></hr>
    <h2 {...{
      "id": "tfn-declaration-task"
    }}>{`TFN Declaration task`}</h2>
    <p>{`This task is to supply the employee with a digitized version of the Tax File Number Declaration from the Australian Taxation Office.
This task verifies the TFN of the employee to ensure it is correct before submission.`}</p>
    <p>{`If the employee is synchronised to Xero this information will be updated on the employee's profile.`}</p>

    <Screen26 mdxType="Screen26" />
    <hr></hr>
    <h2 {...{
      "id": "super-choice-task"
    }}>{`Super Choice task`}</h2>
    <p>{`This task is to supply the employee with a digitized version of the Standard Super Choice form from the Australian Taxation Office.
This task verifies the TFN of the employee to ensure it is correct before submission.`}</p>
    <p>{`If the employee is synchronised to Xero this information will be updated on the employee's profile.`}</p>

    <Screen28 mdxType="Screen28" />
    <h2 {...{
      "id": "bank-account-task"
    }}>{`Bank Account task`}</h2>
    <p>{`This task is to collect banking information from an employee to make payment for wages.
If the employee is synchronised to Xero this information will be updated on the employee's profile.`}</p>

    <Screen30 mdxType="Screen30" />
    <h2 {...{
      "id": "sync-xero-employee-task"
    }}>{`Sync Xero employee task`}</h2>
    <p>{`This task synchronises employee information from Canyou into Xero. As a minimmum the users profile task must be completed before you will be allowed to synchronise.
You can create a new employee or synchronise the information of an existing employee. This task can synchronise the following:`}</p>
    <ul>
      <li parentName="ul">{`User profile`}</li>
      <li parentName="ul">{`Tax File Number Declaration`}</li>
      <li parentName="ul">{`Standard Super Choice form`}</li>
      <li parentName="ul">{`Bank account details`}</li>
    </ul>

    <Screen32 mdxType="Screen32" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      